import { default as aboutr9Bv0AvjdbMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/about.vue?macro=true";
import { default as cabinetWffieJXiKgMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/account/cabinet.vue?macro=true";
import { default as _91id_93dL7UETK0UAMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/actions/[id].vue?macro=true";
import { default as listIwthKdXOONMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/actions/list.vue?macro=true";
import { default as _91id_93R4BHUk0b4nMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/articles/[id].vue?macro=true";
import { default as listyJ8LzbLZ83Meta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/articles/list.vue?macro=true";
import { default as finalStepbSO0bTLJM6Meta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/cart/finalStep.vue?macro=true";
import { default as firstStepJsGF5xf2C1Meta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/cart/firstStep.vue?macro=true";
import { default as secondStepySrkdurwVcMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/cart/secondStep.vue?macro=true";
import { default as contactstC8FOHOlvFMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/contacts.vue?macro=true";
import { default as customPCFjtKvlWvMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/custom.vue?macro=true";
import { default as delivery7l3gXXr9xnMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/delivery.vue?macro=true";
import { default as feedbackBw6ccVo8ltMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/feedback.vue?macro=true";
import { default as indexFYQRSRU1XmMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/index.vue?macro=true";
import { default as legalFohBbGekdtMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/legal.vue?macro=true";
import { default as _91id_93QgR4qumlQgMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue?macro=true";
import { default as _91id_9356Dm5is6NhMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue?macro=true";
import { default as partnershipw2ECXL3TF6Meta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/partnership.vue?macro=true";
import { default as _91id_93M4mxiLCwBrMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/restaurants/[id].vue?macro=true";
import { default as listWWtAst5r18Meta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/restaurants/list.vue?macro=true";
import { default as offdGC2p1oEYYMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/system/off.vue?macro=true";
import { default as vacancyz3LmPrp86YMeta } from "D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/vacancy.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/about.vue")
  },
  {
    name: "account-cabinet",
    path: "/cabinet",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/account/cabinet.vue")
  },
  {
    name: "actions-id",
    path: "/action/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/actions/[id].vue")
  },
  {
    name: "actions-list",
    path: "/actions",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/actions/list.vue")
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/articles/[id].vue")
  },
  {
    name: "articles-list",
    path: "/articles",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/articles/list.vue")
  },
  {
    name: "cart-finalStep",
    path: "/order/complete/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/cart/finalStep.vue")
  },
  {
    name: "cart-firstStep",
    path: "/order",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/cart/firstStep.vue")
  },
  {
    name: "cart-secondStep",
    path: "/order/delivery",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/cart/secondStep.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/contacts.vue")
  },
  {
    name: "custom",
    path: "/custom",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/custom.vue")
  },
  {
    name: "delivery",
    path: "/delivery",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/delivery.vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/feedback.vue")
  },
  {
    name: "index",
    path: "/",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/legal.vue")
  },
  {
    name: "menu-group-id",
    path: "/menu/group/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    name: "menu-product-id",
    path: "/menu/product/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "partnership",
    path: "/partners",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/partnership.vue")
  },
  {
    name: "restaurants-id",
    path: "/restaurants-details/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/restaurants/[id].vue")
  },
  {
    name: "restaurants-list",
    path: "/rest",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/restaurants/list.vue")
  },
  {
    name: "system-off",
    path: "/system/off",
    meta: {"HideHeader":true,"HideFooter":true},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/system/off.vue")
  },
  {
    name: "vacancy",
    path: "/vacancy",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/vacancy.vue")
  },
  {
    name: "group|3b55fc73-9fee-42f6-bc38-b0ff00ace179",
    path: "/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179",
    meta: {"ID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"Title":"Tyo - SOUPS c доставкой на дом","MetaTags":[{"Content":"Раздел SOUPS в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел SOUPS в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179/all",
    name: "group|3b55fc73-9fee-42f6-bc38-b0ff00ace179|redirect-all",
    redirect: "/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179"
  },
  {
    name: "group|d9e3a4a1-d680-43e9-a381-b0ff008b835c",
    path: "/menu/starters-salads",
    meta: {"ID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"Title":"Tyo - STARTERS & SALADS c доставкой на дом","MetaTags":[{"Content":"Раздел STARTERS & SALADS в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел STARTERS & SALADS в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/starters-salads/all",
    name: "group|d9e3a4a1-d680-43e9-a381-b0ff008b835c|redirect-all",
    redirect: "/menu/starters-salads"
  },
  {
    name: "group|755e4926-146b-469e-8146-b0c000e0985e",
    path: "/menu/our-specialities",
    meta: {"ID":"755e4926-146b-469e-8146-b0c000e0985e","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"Title":"Tyo - OUR SPECIALITIES c доставкой на дом","MetaTags":[{"Content":"Раздел OUR SPECIALITIES в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел OUR SPECIALITIES в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/our-specialities/all",
    name: "group|755e4926-146b-469e-8146-b0c000e0985e|redirect-all",
    redirect: "/menu/our-specialities"
  },
  {
    name: "group|f91dd3bb-3468-46d9-87f3-b0bd00e87837",
    path: "/menu/wok",
    meta: {"ID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"Title":"Tyo - WOK c доставкой на дом","SeoText":"","AdditionalSeoText":"","MetaTags":[{"Content":"Раздел WOK в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел WOK в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/wok/all",
    name: "group|f91dd3bb-3468-46d9-87f3-b0bd00e87837|redirect-all",
    redirect: "/menu/wok"
  },
  {
    name: "group|82c5154c-7170-47ac-a475-b0c000dc8f7f",
    path: "/menu/main-course",
    meta: {"ID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"Title":"Tyo - MAIN COURSE c доставкой на дом","MetaTags":[{"Content":"Раздел MAIN COURSE в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел MAIN COURSE в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/main-course/all",
    name: "group|82c5154c-7170-47ac-a475-b0c000dc8f7f|redirect-all",
    redirect: "/menu/main-course"
  },
  {
    name: "group|e4993b01-9066-4989-a427-b0c000c6d023",
    path: "/menu/rolls",
    meta: {"ID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","Title":"Tyo - ROLLS (CALIFORNIA ROLLS) c доставкой на дом","SeoText":"","AdditionalSeoText":"","MetaTags":[{"Content":"Раздел ROLLS (CALIFORNIA ROLLS) в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел ROLLS (CALIFORNIA ROLLS) в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    name: "group|788ab5d8-d06f-44e4-88c6-b0c000c6f623",
    path: "/menu/baked-rolls",
    meta: {"ID":"788ab5d8-d06f-44e4-88c6-b0c000c6f623","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"788ab5d8-d06f-44e4-88c6-b0c000c6f623","Title":"Tyo - ROLLS (BAKED ROLLS) c доставкой на дом","SeoText":"","AdditionalSeoText":"","MetaTags":[{"Content":"Раздел ROLLS (BAKED ROLLS) в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел ROLLS (BAKED ROLLS) в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    name: "group|1c759bdb-1d66-4558-9529-b0c000c712f6",
    path: "/menu/tempura-rolls",
    meta: {"ID":"1c759bdb-1d66-4558-9529-b0c000c712f6","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"1c759bdb-1d66-4558-9529-b0c000c712f6","Title":"Tyo - ROLLS (TEMPURA ROLLS) c доставкой на дом","SeoText":"","AdditionalSeoText":"","MetaTags":[{"Content":"Раздел ROLLS (TEMPURA ROLLS) в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел ROLLS (TEMPURA ROLLS) в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    name: "group|27da4fb9-e571-4255-88f7-b0c000c68462",
    path: "/menu/set",
    meta: {"ID":"27da4fb9-e571-4255-88f7-b0c000c68462","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"27da4fb9-e571-4255-88f7-b0c000c68462","Title":"Tyo - ROLLS (SET) c доставкой на дом","SeoText":"","AdditionalSeoText":"","MetaTags":[{"Content":"Раздел ROLLS (SET) в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел ROLLS (SET) в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    name: "group|5dbd56a9-e944-4fb4-8a05-b0ff00cf7c5b",
    path: "/menu/desserts",
    meta: {"ID":"5dbd56a9-e944-4fb4-8a05-b0ff00cf7c5b","GroupID":"5dbd56a9-e944-4fb4-8a05-b0ff00cf7c5b","SubgroupID":null,"Title":"Tyo - DESSERTS c доставкой на дом","MetaTags":[{"Content":"Раздел DESSERTS в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел DESSERTS в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/desserts/all",
    name: "group|5dbd56a9-e944-4fb4-8a05-b0ff00cf7c5b|redirect-all",
    redirect: "/menu/desserts"
  },
  {
    name: "group|45a5056a-d651-4fe2-9bcb-1c707ba7681d",
    path: "/menu/napitki",
    meta: {"ID":"45a5056a-d651-4fe2-9bcb-1c707ba7681d","GroupID":"45a5056a-d651-4fe2-9bcb-1c707ba7681d","SubgroupID":null,"Title":"Tyo - Напитки c доставкой на дом","MetaTags":[{"Content":"Раздел Напитки в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел Напитки в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/napitki/all",
    name: "group|45a5056a-d651-4fe2-9bcb-1c707ba7681d|redirect-all",
    redirect: "/menu/napitki"
  },
  {
    name: "group|a8758616-9fcd-41bb-be1d-b0ff00eb03e3",
    path: "/menu/sousy",
    meta: {"ID":"a8758616-9fcd-41bb-be1d-b0ff00eb03e3","GroupID":"a8758616-9fcd-41bb-be1d-b0ff00eb03e3","SubgroupID":null,"Title":"Tyo - Соусы c доставкой на дом","MetaTags":[{"Content":"Раздел Соусы в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел Соусы в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/sousy/all",
    name: "group|a8758616-9fcd-41bb-be1d-b0ff00eb03e3|redirect-all",
    redirect: "/menu/sousy"
  },
  {
    name: "group|0ba3e901-4ce3-4e2f-b022-8fc94cf932ef",
    path: "/menu/prochee",
    meta: {"ID":"0ba3e901-4ce3-4e2f-b022-8fc94cf932ef","GroupID":"0ba3e901-4ce3-4e2f-b022-8fc94cf932ef","SubgroupID":null,"Title":"Tyo - Прочее c доставкой на дом","MetaTags":[{"Content":"Раздел Прочее в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Раздел Прочее в меню на сайте Tyo (Ростов-на-Дону). Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/prochee/all",
    name: "group|0ba3e901-4ce3-4e2f-b022-8fc94cf932ef|redirect-all",
    redirect: "/menu/prochee"
  },
  {
    path: "/menu",
    name: "group|redirect-default-link",
    redirect: "/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179"
  },
  {
    name: "product|755e4926-146b-469e-8146-b0c000e0985e|008f7381-dc48-4784-be73-9ae8300fed12",
    path: "/product/gedza-krevetki",
    meta: {"ID":"008f7381-dc48-4784-be73-9ae8300fed12","Title":"Tyo (Ростов-на-Дону) — Доставка Гедза креветки на дом","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"GroupTitle":"OUR SPECIALITIES","GroupLink":"/menu/our-specialities","MetaTags":[{"Content":"Гедза креветки %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Гедза креветки %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|03a2bef0-1031-48c5-b97c-4384345f5ffc",
    path: "/product/zheltoe-karri-so-svininoy-tya-syu",
    meta: {"ID":"03a2bef0-1031-48c5-b97c-4384345f5ffc","Title":"Tyo (Ростов-на-Дону) — Доставка Желтое карри со свининой тя-сю на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Желтое карри со свининой тя-сю %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Желтое карри со свининой тя-сю %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|03dfd468-1188-46c2-88c9-832514ee24f4",
    path: "/product/krasnoe-karri-s-krevetkami",
    meta: {"ID":"03dfd468-1188-46c2-88c9-832514ee24f4","Title":"Tyo (Ростов-на-Дону) — Доставка Красное карри с креветками на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Красное карри с креветками %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Красное карри с креветками %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|0e1736ed-7dce-4569-9b04-c5c80b0324c9",
    path: "/product/tom-yam-kuriniy",
    meta: {"ID":"0e1736ed-7dce-4569-9b04-c5c80b0324c9","Title":"Tyo (Ростов-на-Дону) — Доставка Том-ям куриный на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Том-ям куриный %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том-ям куриный %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|da91ceed-25b7-4701-a997-b10100642a59|1011b778-af6a-4bb3-9bc2-791a443589dd",
    path: "/product/1011b778-af6a-4bb3-9bc2-791a443589dd",
    meta: {"ID":"1011b778-af6a-4bb3-9bc2-791a443589dd","Title":"Tyo (Ростов-на-Дону) — Доставка Швепс Биттер Лемон  на дом","SubgroupID":null,"GroupLink":"","MetaTags":[{"Content":"Швепс Биттер Лемон  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Швепс Биттер Лемон  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|115b997c-9fd2-457d-be0d-eaf2eaeb6aca",
    path: "/product/spring-rolly-s-krevetkoy",
    meta: {"ID":"115b997c-9fd2-457d-be0d-eaf2eaeb6aca","Title":"Tyo (Ростов-на-Дону) — Доставка Спринг роллы с креветкой на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Спринг роллы с креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Спринг роллы с креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|122e1dbb-544f-439a-8dd7-9665e2cd63cc",
    path: "/product/tom-yam-green-vegetarianskiy",
    meta: {"ID":"122e1dbb-544f-439a-8dd7-9665e2cd63cc","Title":"Tyo (Ростов-на-Дону) — Доставка Том-ям Green вегетарианский на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Том-ям Green вегетарианский %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том-ям Green вегетарианский %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|14d37a82-be94-46c3-9632-0691e02a4b6c",
    path: "/product/krevetki-v-souse-karri",
    meta: {"ID":"14d37a82-be94-46c3-9632-0691e02a4b6c","Title":"Tyo (Ростов-на-Дону) — Доставка Креветки в соусе карри на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Креветки в соусе карри %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Креветки в соусе карри %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|19cefd70-ff39-49b0-b3cf-b28c11d92820",
    path: "/product/zelenoe-karri-s-krevetkami",
    meta: {"ID":"19cefd70-ff39-49b0-b3cf-b28c11d92820","Title":"Tyo (Ростов-на-Дону) — Доставка Зеленое карри с креветками на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Зеленое карри с креветками %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Зеленое карри с креветками %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|755e4926-146b-469e-8146-b0c000e0985e|1cb2cb33-4a94-410b-a216-7735f157a745",
    path: "/product/1cb2cb33-4a94-410b-a216-7735f157a745",
    meta: {"ID":"1cb2cb33-4a94-410b-a216-7735f157a745","Title":"Tyo (Ростов-на-Дону) — Доставка Ротти с угрем , гуакамоле и кокосовый крем на дом","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"GroupTitle":"OUR SPECIALITIES","GroupLink":"/menu/our-specialities","MetaTags":[{"Content":"Ротти с угрем , гуакамоле и кокосовый крем %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ротти с угрем , гуакамоле и кокосовый крем %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|1dd4ebf2-8a35-4d5e-8e96-3f89a5f590fa",
    path: "/product/tom-yam-green-s-moreproduktami",
    meta: {"ID":"1dd4ebf2-8a35-4d5e-8e96-3f89a5f590fa","Title":"Tyo (Ростов-на-Дону) — Доставка Том ям GREEN с морепродуктами на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Том ям GREEN с морепродуктами %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том ям GREEN с морепродуктами %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|1e417d20-f0f0-4672-9742-bef8f739040a",
    path: "/product/filadelfiya-s-lososem",
    meta: {"ID":"1e417d20-f0f0-4672-9742-bef8f739040a","Title":"Tyo (Ростов-на-Дону) — Доставка Филадельфия с лососем на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Филадельфия с лососем %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Филадельфия с лососем %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|202e75b8-50cd-4803-8ff3-909290acaf58",
    path: "/product/khrustyaschiy-kalmar-tempura",
    meta: {"ID":"202e75b8-50cd-4803-8ff3-909290acaf58","Title":"Tyo (Ростов-на-Дону) — Доставка Хрустящий кальмар-темпура на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Хрустящий кальмар-темпура %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хрустящий кальмар-темпура %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|223625af-62fb-4fe4-9058-3cece7f928bf",
    path: "/product/223625af-62fb-4fe4-9058-3cece7f928bf",
    meta: {"ID":"223625af-62fb-4fe4-9058-3cece7f928bf","Title":"Tyo (Ростов-на-Дону) — Доставка Бао булочка 1 шт на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Бао булочка 1 шт %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Бао булочка 1 шт %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|228edcf2-75c3-46b0-8652-6eb50b47e907",
    path: "/product/tsezar-s-kuritsey-karri",
    meta: {"ID":"228edcf2-75c3-46b0-8652-6eb50b47e907","Title":"Tyo (Ростов-на-Дону) — Доставка Цезарь с курицей карри на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Цезарь с курицей карри %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Цезарь с курицей карри %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|230344fd-0409-4077-adce-9c90dfc1fdb2",
    path: "/product/bangkok",
    meta: {"ID":"230344fd-0409-4077-adce-9c90dfc1fdb2","Title":"Tyo (Ростов-на-Дону) — Доставка Бангкок на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Бангкок %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Бангкок %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|25b6a4e1-15dc-4470-9d2f-61603f80c702",
    path: "/product/salat-s-khrustyaschimi-baklazhanami",
    meta: {"ID":"25b6a4e1-15dc-4470-9d2f-61603f80c702","Title":"Tyo (Ростов-на-Дону) — Доставка Салат с хрустящими баклажанами на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Салат с хрустящими баклажанами %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Салат с хрустящими баклажанами %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|45a5056a-d651-4fe2-9bcb-1c707ba7681d|26d16c48-608a-4a2e-973d-a6226d2c74d2",
    path: "/product/0-2-sok-rich-cristal",
    meta: {"ID":"26d16c48-608a-4a2e-973d-a6226d2c74d2","Title":"Tyo (Ростов-на-Дону) — Доставка 0,2 Сок Рич Cristal на дом","GroupID":"45a5056a-d651-4fe2-9bcb-1c707ba7681d","SubgroupID":null,"GroupTitle":"Напитки","GroupLink":"/menu/napitki","MetaTags":[{"Content":"0,2 Сок Рич Cristal %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"0,2 Сок Рич Cristal %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|788ab5d8-d06f-44e4-88c6-b0c000c6f623|2977b38f-9804-427f-9893-cc3708d21949",
    path: "/product/ebi-hot",
    meta: {"ID":"2977b38f-9804-427f-9893-cc3708d21949","Title":"Tyo (Ростов-на-Дону) — Доставка Эби Hot на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"788ab5d8-d06f-44e4-88c6-b0c000c6f623","GroupTitle":"BAKED ROLLS","GroupLink":"/menu/baked-rolls","MetaTags":[{"Content":"Эби Hot %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Эби Hot %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|2a34d967-585e-478b-bcb9-231e9a7092e3",
    path: "/product/2a34d967-585e-478b-bcb9-231e9a7092e3",
    meta: {"ID":"2a34d967-585e-478b-bcb9-231e9a7092e3","Title":"Tyo (Ростов-на-Дону) — Доставка Рис для том ям на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Рис для том ям %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Рис для том ям %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|755e4926-146b-469e-8146-b0c000e0985e|2e89bb6b-4e68-4ce0-abe8-e8536872db0c",
    path: "/product/lepeshka-roti-s-krevetkami-zelenym-mango-i-sousom-iz-kinzy",
    meta: {"ID":"2e89bb6b-4e68-4ce0-abe8-e8536872db0c","Title":"Tyo (Ростов-на-Дону) — Доставка Лепешка роти с креветками зеленым манго и соусом из кинзы на дом","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"GroupTitle":"OUR SPECIALITIES","GroupLink":"/menu/our-specialities","MetaTags":[{"Content":"Лепешка роти с креветками зеленым манго и соусом из кинзы %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лепешка роти с креветками зеленым манго и соусом из кинзы %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|3036b727-895d-436c-9fe6-7a922c9e9df5",
    path: "/product/ataraschi",
    meta: {"ID":"3036b727-895d-436c-9fe6-7a922c9e9df5","Title":"Tyo (Ростов-на-Дону) — Доставка Атаращи на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Атаращи %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Атаращи %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|da91ceed-25b7-4701-a997-b10100642a59|30a7f291-0512-4f94-b7cb-8dd145641360",
    path: "/product/30a7f291-0512-4f94-b7cb-8dd145641360",
    meta: {"ID":"30a7f291-0512-4f94-b7cb-8dd145641360","Title":"Tyo (Ростов-на-Дону) — Доставка 0,2 Сок Рич Cristal яблоко на дом","SubgroupID":null,"GroupLink":"","MetaTags":[{"Content":"0,2 Сок Рич Cristal яблоко %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"0,2 Сок Рич Cristal яблоко %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|31373ef9-8d1a-4da5-b243-e3ccbdd46ae8",
    path: "/product/frayd-rays-vegan",
    meta: {"ID":"31373ef9-8d1a-4da5-b243-e3ccbdd46ae8","Title":"Tyo (Ростов-на-Дону) — Доставка Фрайд райс веган на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Фрайд райс веган %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Фрайд райс веган %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|318b1007-14ea-41f6-88fb-9a38f95bf4d1",
    path: "/product/frayd-rays-tsyplenok",
    meta: {"ID":"318b1007-14ea-41f6-88fb-9a38f95bf4d1","Title":"Tyo (Ростов-на-Дону) — Доставка Фрайд райс цыпленок на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Фрайд райс цыпленок %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Фрайд райс цыпленок %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|31e1a2b1-31be-4bae-ab36-bbd9a6c2f493",
    path: "/product/tom-yam-noodle-s-kuritsey",
    meta: {"ID":"31e1a2b1-31be-4bae-ab36-bbd9a6c2f493","Title":"Tyo (Ростов-на-Дону) — Доставка Том-ям noodle с курицей на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Том-ям noodle с курицей %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том-ям noodle с курицей %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|da91ceed-25b7-4701-a997-b10100642a59|342fa654-0cf1-44ac-83f9-798be950bab9",
    path: "/product/342fa654-0cf1-44ac-83f9-798be950bab9",
    meta: {"ID":"342fa654-0cf1-44ac-83f9-798be950bab9","Title":"Tyo (Ростов-на-Дону) — Доставка Швепс Индиан Тоник на дом","SubgroupID":null,"GroupLink":"","MetaTags":[{"Content":"Швепс Индиан Тоник %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Швепс Индиан Тоник %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|34e702a3-c16d-4afa-8808-a7757de2f4ac",
    path: "/product/alyaska",
    meta: {"ID":"34e702a3-c16d-4afa-8808-a7757de2f4ac","Title":"Tyo (Ростов-на-Дону) — Доставка Аляска на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Аляска %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Аляска %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|35e44d80-8c28-4fc3-ac82-d26bdcd48733",
    path: "/product/35e44d80-8c28-4fc3-ac82-d26bdcd48733",
    meta: {"ID":"35e44d80-8c28-4fc3-ac82-d26bdcd48733","Title":"Tyo (Ростов-на-Дону) — Доставка Тар Тар из тунца на бао  на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Тар Тар из тунца на бао  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тар Тар из тунца на бао  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|755e4926-146b-469e-8146-b0c000e0985e|36b0cde3-4507-461f-9d13-e14a5f484297",
    path: "/product/gedza-govyadina",
    meta: {"ID":"36b0cde3-4507-461f-9d13-e14a5f484297","Title":"Tyo (Ростов-на-Дону) — Доставка Гедза говядина на дом","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"GroupTitle":"OUR SPECIALITIES","GroupLink":"/menu/our-specialities","MetaTags":[{"Content":"Гедза говядина %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Гедза говядина %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|36fe772b-e311-4c1f-a810-1289dc82a84e",
    path: "/product/zelenoe-karri-so-svininoy-tya-syu",
    meta: {"ID":"36fe772b-e311-4c1f-a810-1289dc82a84e","Title":"Tyo (Ростов-на-Дону) — Доставка Зеленое карри со свининой тя-сю на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Зеленое карри со свининой тя-сю %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Зеленое карри со свининой тя-сю %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|27da4fb9-e571-4255-88f7-b0c000c68462|3c3bc2ac-ca88-468c-8671-02ac560166e6",
    path: "/product/assorti-2",
    meta: {"ID":"3c3bc2ac-ca88-468c-8671-02ac560166e6","Title":"Tyo (Ростов-на-Дону) — Доставка Ассорти 2 на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"27da4fb9-e571-4255-88f7-b0c000c68462","GroupTitle":"SET","GroupLink":"/menu/set","MetaTags":[{"Content":"Ассорти 2 %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ассорти 2 %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|788ab5d8-d06f-44e4-88c6-b0c000c6f623|41f23a25-306a-4cc9-af8e-cd643ea84a85",
    path: "/product/kaliforniya-hot-s-lososem-spays",
    meta: {"ID":"41f23a25-306a-4cc9-af8e-cd643ea84a85","Title":"Tyo (Ростов-на-Дону) — Доставка Калифорния HOT с лососем спайс на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"788ab5d8-d06f-44e4-88c6-b0c000c6f623","GroupTitle":"BAKED ROLLS","GroupLink":"/menu/baked-rolls","MetaTags":[{"Content":"Калифорния HOT с лососем спайс %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Калифорния HOT с лососем спайс %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|45a5056a-d651-4fe2-9bcb-1c707ba7681d|47745da7-5b7c-45f4-ab57-b131ab18e498",
    path: "/product/limonad-aranchata",
    meta: {"ID":"47745da7-5b7c-45f4-ab57-b131ab18e498","Title":"Tyo (Ростов-на-Дону) — Доставка Лимонад Аранчата на дом","GroupID":"45a5056a-d651-4fe2-9bcb-1c707ba7681d","SubgroupID":null,"GroupTitle":"Напитки","GroupLink":"/menu/napitki","MetaTags":[{"Content":"Лимонад Аранчата %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лимонад Аранчата %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|4d27f3bd-7273-43d0-bb0a-28adfccf8cda",
    path: "/product/tom-kkha-s-moreproduktami",
    meta: {"ID":"4d27f3bd-7273-43d0-bb0a-28adfccf8cda","Title":"Tyo (Ростов-на-Дону) — Доставка Том-кха с морепродуктами на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Том-кха с морепродуктами %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том-кха с морепродуктами %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|502d90fa-e773-4dd2-8efc-f4fa0da355f5",
    path: "/product/boul-s-krevetkoy-karri",
    meta: {"ID":"502d90fa-e773-4dd2-8efc-f4fa0da355f5","Title":"Tyo (Ростов-на-Дону) — Доставка Боул с креветкой карри на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Боул с креветкой карри %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Боул с креветкой карри %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|755e4926-146b-469e-8146-b0c000e0985e|51a45397-4001-4cae-8124-127bfb5dd6b2",
    path: "/product/bao-burger-s-krevetkoy",
    meta: {"ID":"51a45397-4001-4cae-8124-127bfb5dd6b2","Title":"Tyo (Ростов-на-Дону) — Доставка Бао-бургер с креветкой на дом","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"GroupTitle":"OUR SPECIALITIES","GroupLink":"/menu/our-specialities","MetaTags":[{"Content":"Бао-бургер с креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Бао-бургер с креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|52f4a5f5-d9f7-4c22-a77d-46a5139b3658",
    path: "/product/kaliforniya-s-lososem",
    meta: {"ID":"52f4a5f5-d9f7-4c22-a77d-46a5139b3658","Title":"Tyo (Ростов-на-Дону) — Доставка Калифорния с лососем на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Калифорния с лососем %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Калифорния с лососем %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|57edfb94-32ca-4340-bd7c-c673ed7d4b5f",
    path: "/product/spring-roll-tsyplenok",
    meta: {"ID":"57edfb94-32ca-4340-bd7c-c673ed7d4b5f","Title":"Tyo (Ростов-на-Дону) — Доставка Спринг ролл цыпленок на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Спринг ролл цыпленок %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Спринг ролл цыпленок %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|788ab5d8-d06f-44e4-88c6-b0c000c6f623|5829a1b7-1469-4591-a21b-c32ecf1254ea",
    path: "/product/dinamit",
    meta: {"ID":"5829a1b7-1469-4591-a21b-c32ecf1254ea","Title":"Tyo (Ростов-на-Дону) — Доставка Динамит на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"788ab5d8-d06f-44e4-88c6-b0c000c6f623","GroupTitle":"BAKED ROLLS","GroupLink":"/menu/baked-rolls","MetaTags":[{"Content":"Динамит %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Динамит %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|5a5b0265-ecaa-45f1-81b6-983e34ad9395",
    path: "/product/5a5b0265-ecaa-45f1-81b6-983e34ad9395",
    meta: {"ID":"5a5b0265-ecaa-45f1-81b6-983e34ad9395","Title":"Tyo (Ростов-на-Дону) — Доставка Запеченные мидии том-ям спайс на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Запеченные мидии том-ям спайс %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Запеченные мидии том-ям спайс %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|5b91a54b-a2ff-4c01-8867-353deafe6d3e",
    path: "/product/tom-yam-vegetarianskiy",
    meta: {"ID":"5b91a54b-a2ff-4c01-8867-353deafe6d3e","Title":"Tyo (Ростов-на-Дону) — Доставка Том-ям вегетарианский на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Том-ям вегетарианский %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том-ям вегетарианский %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|5d26aa6c-0c9f-400c-9424-dbab3ff27694",
    path: "/product/tom-yam-s-moreproduktami",
    meta: {"ID":"5d26aa6c-0c9f-400c-9424-dbab3ff27694","Title":"Tyo (Ростов-на-Дону) — Доставка Том-ям с морепродуктами на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Том-ям с морепродуктами %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том-ям с морепродуктами %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|61641b16-a93b-4e60-bacc-e02b15702a14",
    path: "/product/frayd-rays-krevetki",
    meta: {"ID":"61641b16-a93b-4e60-bacc-e02b15702a14","Title":"Tyo (Ростов-на-Дону) — Доставка Фрайд райс креветки на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Фрайд райс креветки %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Фрайд райс креветки %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|6853b61c-85b9-4912-b1ec-ea62e61607bb",
    path: "/product/tom-yam-noodle-c-krevetkoy",
    meta: {"ID":"6853b61c-85b9-4912-b1ec-ea62e61607bb","Title":"Tyo (Ростов-на-Дону) — Доставка Том-ям noodle c креветкой на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Том-ям noodle c креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том-ям noodle c креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|686740d9-ce13-40df-9edc-6dd305faa9ce",
    path: "/product/frayd-rays-svinina-tya-syu",
    meta: {"ID":"686740d9-ce13-40df-9edc-6dd305faa9ce","Title":"Tyo (Ростов-на-Дону) — Доставка Фрайд Райс свинина тя-сю на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Фрайд Райс свинина тя-сю %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Фрайд Райс свинина тя-сю %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|1c759bdb-1d66-4558-9529-b0c000c712f6|68ab5d83-8ff4-430c-a385-36df3c0c0638",
    path: "/product/fumisava-tempura",
    meta: {"ID":"68ab5d83-8ff4-430c-a385-36df3c0c0638","Title":"Tyo (Ростов-на-Дону) — Доставка Фумисава темпура на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"1c759bdb-1d66-4558-9529-b0c000c712f6","GroupTitle":"TEMPURA ROLLS","GroupLink":"/menu/tempura-rolls","MetaTags":[{"Content":"Фумисава темпура %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Фумисава темпура %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|6bcb69f1-6354-4606-9efe-340f66d4cf2e",
    path: "/product/khend-roll-c-krevetkoy",
    meta: {"ID":"6bcb69f1-6354-4606-9efe-340f66d4cf2e","Title":"Tyo (Ростов-на-Дону) — Доставка Хенд ролл c креветкой на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","SeoText":"","AdditionalSeoText":"","MetaTags":[{"Content":"Хенд ролл c креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хенд ролл c креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|6c35744e-e33d-4ca3-a121-ae3e23338fe3",
    path: "/product/khend-roll-s-lososem",
    meta: {"ID":"6c35744e-e33d-4ca3-a121-ae3e23338fe3","Title":"Tyo (Ростов-на-Дону) — Доставка Хенд ролл с лососем на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Хенд ролл с лососем %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хенд ролл с лососем %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|6c99b467-2c06-4138-b2d2-b7eacb2c885d",
    path: "/product/6c99b467-2c06-4138-b2d2-b7eacb2c885d",
    meta: {"ID":"6c99b467-2c06-4138-b2d2-b7eacb2c885d","Title":"Tyo (Ростов-на-Дону) — Доставка Фирменный Ролл ТУО на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Фирменный Ролл ТУО %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Фирменный Ролл ТУО %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|6f1287cb-f2e9-42b1-9fb9-0efee67cf1a5",
    path: "/product/kitayskaya-lapsha-tsyplenok",
    meta: {"ID":"6f1287cb-f2e9-42b1-9fb9-0efee67cf1a5","Title":"Tyo (Ростов-на-Дону) — Доставка Китайская лапша цыпленок на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Китайская лапша цыпленок %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Китайская лапша цыпленок %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|76059438-40f7-412b-9741-daa29f68689d",
    path: "/product/76059438-40f7-412b-9741-daa29f68689d",
    meta: {"ID":"76059438-40f7-412b-9741-daa29f68689d","Title":"Tyo (Ростов-на-Дону) — Доставка Тайский Сливочный с морепродуктами  на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Тайский Сливочный с морепродуктами  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тайский Сливочный с морепродуктами  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|78163690-3128-4c2c-8f44-376da9e90b65",
    path: "/product/tayskiy-vok-v-kislo-sladkom-souse-s-svininoy",
    meta: {"ID":"78163690-3128-4c2c-8f44-376da9e90b65","Title":"Tyo (Ростов-на-Дону) — Доставка Тайский вок в кисло-сладком соусе с свининой на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Тайский вок в кисло-сладком соусе с свининой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тайский вок в кисло-сладком соусе с свининой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|7d62e7a9-e85c-40ed-ab45-3cd20250561d",
    path: "/product/tom-kkha-vegetarianskiy",
    meta: {"ID":"7d62e7a9-e85c-40ed-ab45-3cd20250561d","Title":"Tyo (Ростов-на-Дону) — Доставка Том Кха вегетарианский на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Том Кха вегетарианский %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том Кха вегетарианский %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|7e9ab459-5bcb-48a7-9dbe-9a3da611a4ea",
    path: "/product/ugolnye-krevetki-sous-mango-chili",
    meta: {"ID":"7e9ab459-5bcb-48a7-9dbe-9a3da611a4ea","Title":"Tyo (Ростов-на-Дону) — Доставка Угольные креветки, соус манго-чили на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Угольные креветки, соус манго-чили %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Угольные креветки, соус манго-чили %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|7f2e2271-7b14-4d20-9d8a-87b3605f43d7",
    path: "/product/kitayskaya-lapsha-s-krevetkami",
    meta: {"ID":"7f2e2271-7b14-4d20-9d8a-87b3605f43d7","Title":"Tyo (Ростов-на-Дону) — Доставка Китайская лапша с креветками на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Китайская лапша с креветками %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Китайская лапша с креветками %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|801fe10b-e167-48a9-b80c-b2d6c3e8a790",
    path: "/product/green-salad",
    meta: {"ID":"801fe10b-e167-48a9-b80c-b2d6c3e8a790","Title":"Tyo (Ростов-на-Дону) — Доставка Green salad на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Green salad %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Green salad %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|82708531-4c55-46ef-87a9-55ecd448519a",
    path: "/product/tom-yam-green-s-kuritsey",
    meta: {"ID":"82708531-4c55-46ef-87a9-55ecd448519a","Title":"Tyo (Ростов-на-Дону) — Доставка Том-ям GREEN с курицей на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Том-ям GREEN с курицей %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том-ям GREEN с курицей %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|b73a3b1c-c014-4ca9-94bf-b0bd00e1ef04|86d9bc08-b4ff-4fc9-a99b-80ea4914bd12",
    path: "/product/86d9bc08-b4ff-4fc9-a99b-80ea4914bd12",
    meta: {"ID":"86d9bc08-b4ff-4fc9-a99b-80ea4914bd12","Title":"Tyo (Ростов-на-Дону) — Доставка Темп. ролл Фумисава т подарок  на дом","SubgroupID":null,"GroupLink":"","MetaTags":[{"Content":"Темп. ролл Фумисава т подарок  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Темп. ролл Фумисава т подарок  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|755e4926-146b-469e-8146-b0c000e0985e|874873b4-0c68-498f-9077-5aa240a2de63",
    path: "/product/chebureki-kuritsa",
    meta: {"ID":"874873b4-0c68-498f-9077-5aa240a2de63","Title":"Tyo (Ростов-на-Дону) — Доставка Чебуреки курица на дом","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"GroupTitle":"OUR SPECIALITIES","GroupLink":"/menu/our-specialities","MetaTags":[{"Content":"Чебуреки курица %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Чебуреки курица %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|889bbc18-7e69-43c0-b78c-09f08162b95e",
    path: "/product/889bbc18-7e69-43c0-b78c-09f08162b95e",
    meta: {"ID":"889bbc18-7e69-43c0-b78c-09f08162b95e","Title":"Tyo (Ростов-на-Дону) — Доставка Салат с угрем и личи на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Салат с угрем и личи %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Салат с угрем и личи %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8758616-9fcd-41bb-be1d-b0ff00eb03e3|8cba51a8-15a1-4c77-8ebb-de93f46cbc2b",
    path: "/product/sous-spays-40gr",
    meta: {"ID":"8cba51a8-15a1-4c77-8ebb-de93f46cbc2b","Title":"Tyo (Ростов-на-Дону) — Доставка Соус Спайс 40гр на дом","GroupID":"a8758616-9fcd-41bb-be1d-b0ff00eb03e3","SubgroupID":null,"GroupTitle":"Соусы","GroupLink":"/menu/sousy","MetaTags":[{"Content":"Соус Спайс 40гр %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Соус Спайс 40гр %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|8d888689-7261-44b3-b88a-311ab73cb643",
    path: "/product/zelenoe-karri-s-tsyplenkom",
    meta: {"ID":"8d888689-7261-44b3-b88a-311ab73cb643","Title":"Tyo (Ростов-на-Дону) — Доставка Зеленое карри с цыпленком на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Зеленое карри с цыпленком %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Зеленое карри с цыпленком %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|8f522b8c-836f-470a-a7fe-a11013f0600e",
    path: "/product/khrustyaschie-krevetki-k-pivu",
    meta: {"ID":"8f522b8c-836f-470a-a7fe-a11013f0600e","Title":"Tyo (Ростов-на-Дону) — Доставка Хрустящие креветки к пиву на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Хрустящие креветки к пиву %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хрустящие креветки к пиву %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|911383eb-fdb0-42a1-a8bc-c593bd2060ec",
    path: "/product/surin",
    meta: {"ID":"911383eb-fdb0-42a1-a8bc-c593bd2060ec","Title":"Tyo (Ростов-на-Дону) — Доставка Сурин на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Сурин %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Сурин %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|27da4fb9-e571-4255-88f7-b0c000c68462|9700db34-f9b8-4a7d-9257-b70c2f0148e0",
    path: "/product/assorti-1",
    meta: {"ID":"9700db34-f9b8-4a7d-9257-b70c2f0148e0","Title":"Tyo (Ростов-на-Дону) — Доставка Ассорти 1 на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"27da4fb9-e571-4255-88f7-b0c000c68462","GroupTitle":"SET","GroupLink":"/menu/set","MetaTags":[{"Content":"Ассорти 1 %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ассорти 1 %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|da91ceed-25b7-4701-a997-b10100642a59|97777795-061d-4290-a4c8-8d638272d97c",
    path: "/product/97777795-061d-4290-a4c8-8d638272d97c",
    meta: {"ID":"97777795-061d-4290-a4c8-8d638272d97c","Title":"Tyo (Ростов-на-Дону) — Доставка 0,2 Сок Рич Cristal  вишня на дом","SubgroupID":null,"GroupLink":"","MetaTags":[{"Content":"0,2 Сок Рич Cristal  вишня %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"0,2 Сок Рич Cristal  вишня %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|788ab5d8-d06f-44e4-88c6-b0c000c6f623|99c12e18-c4f0-4c87-9918-34f9c6c655c2",
    path: "/product/kaliforniya-hot-s-krevetkami",
    meta: {"ID":"99c12e18-c4f0-4c87-9918-34f9c6c655c2","Title":"Tyo (Ростов-на-Дону) — Доставка Калифорния HOT с креветками на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"788ab5d8-d06f-44e4-88c6-b0c000c6f623","GroupTitle":"BAKED ROLLS","GroupLink":"/menu/baked-rolls","MetaTags":[{"Content":"Калифорния HOT с креветками %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Калифорния HOT с креветками %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8758616-9fcd-41bb-be1d-b0ff00eb03e3|a0a7bbae-c3ca-4d95-9430-bd223548f9a2",
    path: "/product/sous-tuo-25-gr",
    meta: {"ID":"a0a7bbae-c3ca-4d95-9430-bd223548f9a2","Title":"Tyo (Ростов-на-Дону) — Доставка Соус ТУО 25 гр на дом","GroupID":"a8758616-9fcd-41bb-be1d-b0ff00eb03e3","SubgroupID":null,"GroupTitle":"Соусы","GroupLink":"/menu/sousy","MetaTags":[{"Content":"Соус ТУО 25 гр %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Соус ТУО 25 гр %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|755e4926-146b-469e-8146-b0c000e0985e|a5acc847-65fe-44da-a47c-d481a87e956a",
    path: "/product/chebureki-krevetki",
    meta: {"ID":"a5acc847-65fe-44da-a47c-d481a87e956a","Title":"Tyo (Ростов-на-Дону) — Доставка Чебуреки креветки на дом","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"GroupTitle":"OUR SPECIALITIES","GroupLink":"/menu/our-specialities","MetaTags":[{"Content":"Чебуреки креветки %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Чебуреки креветки %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|a7bb9fa5-f937-4fc4-b853-c0aec3a17e1a",
    path: "/product/kaliforniya-s-krevetkoy",
    meta: {"ID":"a7bb9fa5-f937-4fc4-b853-c0aec3a17e1a","Title":"Tyo (Ростов-на-Дону) — Доставка Калифорния с креветкой на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Калифорния с креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Калифорния с креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b55fc73-9fee-42f6-bc38-b0ff00ace179|a883fec3-1898-49b4-9092-808bc58bfefa",
    path: "/product/tom-kkha-s-kuritsey",
    meta: {"ID":"a883fec3-1898-49b4-9092-808bc58bfefa","Title":"Tyo (Ростов-на-Дону) — Доставка Том-Кха с курицей на дом","GroupID":"3b55fc73-9fee-42f6-bc38-b0ff00ace179","SubgroupID":null,"GroupTitle":"SOUPS","GroupLink":"/menu/3b55fc73-9fee-42f6-bc38-b0ff00ace179","MetaTags":[{"Content":"Том-Кха с курицей %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том-Кха с курицей %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|a8ed5a62-b866-4fa7-bffb-d9afd691c9fe",
    path: "/product/a8ed5a62-b866-4fa7-bffb-d9afd691c9fe",
    meta: {"ID":"a8ed5a62-b866-4fa7-bffb-d9afd691c9fe","Title":"Tyo (Ростов-на-Дону) — Доставка Рисовые пончики  на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Рисовые пончики  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Рисовые пончики  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|da91ceed-25b7-4701-a997-b10100642a59|aa86ce3b-a1fa-4a46-a272-fbfd1dfd5bad",
    path: "/product/aa86ce3b-a1fa-4a46-a272-fbfd1dfd5bad",
    meta: {"ID":"aa86ce3b-a1fa-4a46-a272-fbfd1dfd5bad","Title":"Tyo (Ростов-на-Дону) — Доставка 0,2 Сок Рич Cristal  томат на дом","SubgroupID":null,"GroupLink":"","MetaTags":[{"Content":"0,2 Сок Рич Cristal  томат %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"0,2 Сок Рич Cristal  томат %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|da91ceed-25b7-4701-a997-b10100642a59|ac9fdeb5-067d-4634-938c-721e8d777960",
    path: "/product/ac9fdeb5-067d-4634-938c-721e8d777960",
    meta: {"ID":"ac9fdeb5-067d-4634-938c-721e8d777960","Title":"Tyo (Ростов-на-Дону) — Доставка 0,2 Сок Рич Cristal  апельсин на дом","SubgroupID":null,"GroupLink":"","MetaTags":[{"Content":"0,2 Сок Рич Cristal  апельсин %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"0,2 Сок Рич Cristal  апельсин %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|1c759bdb-1d66-4558-9529-b0c000c712f6|aed4fc98-15d8-4251-9fee-27e0be451ac3",
    path: "/product/chaynat-tempura",
    meta: {"ID":"aed4fc98-15d8-4251-9fee-27e0be451ac3","Title":"Tyo (Ростов-на-Дону) — Доставка Чайнат темпура на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"1c759bdb-1d66-4558-9529-b0c000c712f6","GroupTitle":"TEMPURA ROLLS","GroupLink":"/menu/tempura-rolls","MetaTags":[{"Content":"Чайнат темпура %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Чайнат темпура %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|b497a2f3-69b4-49a9-b0b4-ce1e789c1a2b",
    path: "/product/khend-roll-s-tuntsom",
    meta: {"ID":"b497a2f3-69b4-49a9-b0b4-ce1e789c1a2b","Title":"Tyo (Ростов-на-Дону) — Доставка Хенд ролл с тунцом на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Хенд ролл с тунцом %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хенд ролл с тунцом %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|bb2445ee-e5f5-441d-a81b-0e6de987200f",
    path: "/product/krasnoe-karri-s-tsyplenkom",
    meta: {"ID":"bb2445ee-e5f5-441d-a81b-0e6de987200f","Title":"Tyo (Ростов-на-Дону) — Доставка Красное карри с цыпленком на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Красное карри с цыпленком %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Красное карри с цыпленком %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|be8384fe-1cd5-46ed-bad4-2812667b4612",
    path: "/product/boul-s-kuritsey-karri",
    meta: {"ID":"be8384fe-1cd5-46ed-bad4-2812667b4612","Title":"Tyo (Ростов-на-Дону) — Доставка Боул с курицей карри на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Боул с курицей карри %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Боул с курицей карри %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|788ab5d8-d06f-44e4-88c6-b0c000c6f623|c2b5896f-f464-460b-bf23-bf1d4cac53eb",
    path: "/product/pkhuket",
    meta: {"ID":"c2b5896f-f464-460b-bf23-bf1d4cac53eb","Title":"Tyo (Ростов-на-Дону) — Доставка Пхукет на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"788ab5d8-d06f-44e4-88c6-b0c000c6f623","GroupTitle":"BAKED ROLLS","GroupLink":"/menu/baked-rolls","MetaTags":[{"Content":"Пхукет %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пхукет %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|c2b7348e-b824-4c78-942b-601333ff4dd6",
    path: "/product/krevetki-v-souse-vasabi",
    meta: {"ID":"c2b7348e-b824-4c78-942b-601333ff4dd6","Title":"Tyo (Ростов-на-Дону) — Доставка Креветки в соусе васаби на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Креветки в соусе васаби %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Креветки в соусе васаби %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|c2c92c88-6444-489d-9c04-55bc9081aafb",
    path: "/product/c2c92c88-6444-489d-9c04-55bc9081aafb",
    meta: {"ID":"c2c92c88-6444-489d-9c04-55bc9081aafb","Title":"Tyo (Ростов-на-Дону) — Доставка Салат с креветками и манго на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Салат с креветками и манго %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Салат с креветками и манго %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|755e4926-146b-469e-8146-b0c000e0985e|c2e5898c-f888-4647-9da4-7fee460796bc",
    path: "/product/bao-burger-s-tsyplenkom",
    meta: {"ID":"c2e5898c-f888-4647-9da4-7fee460796bc","Title":"Tyo (Ростов-на-Дону) — Доставка Бао-бургер с цыпленком на дом","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"GroupTitle":"OUR SPECIALITIES","GroupLink":"/menu/our-specialities","MetaTags":[{"Content":"Бао-бургер с цыпленком %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Бао-бургер с цыпленком %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|c3bff48b-e17d-4406-9e6c-e0b1f7b70df2",
    path: "/product/bitye-ogurtsy",
    meta: {"ID":"c3bff48b-e17d-4406-9e6c-e0b1f7b70df2","Title":"Tyo (Ростов-на-Дону) — Доставка Битые огурцы на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Битые огурцы %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Битые огурцы %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|c6bdd16e-0002-4415-8df0-7eaf0b732bc1",
    path: "/product/assorti-kimchi",
    meta: {"ID":"c6bdd16e-0002-4415-8df0-7eaf0b732bc1","Title":"Tyo (Ростов-на-Дону) — Доставка Ассорти кимчи на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Ассорти кимчи %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ассорти кимчи %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|cd34e207-966f-4e7f-8a1d-c8057427659f",
    path: "/product/filadelfiya-s-ugrem",
    meta: {"ID":"cd34e207-966f-4e7f-8a1d-c8057427659f","Title":"Tyo (Ростов-на-Дону) — Доставка Филадельфия с угрем на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Филадельфия с угрем %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Филадельфия с угрем %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|0ba3e901-4ce3-4e2f-b022-8fc94cf932ef|d4887f98-1474-43da-8a9a-80a2822184fb",
    path: "/product/stiker-tuo-1sht",
    meta: {"ID":"d4887f98-1474-43da-8a9a-80a2822184fb","Title":"Tyo (Ростов-на-Дону) — Доставка Стикер ТУО 1шт на дом","GroupID":"0ba3e901-4ce3-4e2f-b022-8fc94cf932ef","SubgroupID":null,"GroupTitle":"Прочее","GroupLink":"","MetaTags":[{"Content":"Стикер ТУО 1шт %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Стикер ТУО 1шт %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|5dbd56a9-e944-4fb4-8a05-b0ff00cf7c5b|d8bfc4d1-c157-492d-aac9-7458d821f3c9",
    path: "/product/chia-mango",
    meta: {"ID":"d8bfc4d1-c157-492d-aac9-7458d821f3c9","Title":"Tyo (Ростов-на-Дону) — Доставка Чиа манго  на дом","GroupID":"5dbd56a9-e944-4fb4-8a05-b0ff00cf7c5b","SubgroupID":null,"GroupTitle":"DESSERTS","GroupLink":"","MetaTags":[{"Content":"Чиа манго  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Чиа манго  %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|45a5056a-d651-4fe2-9bcb-1c707ba7681d|d920af15-b5d0-4587-a63b-a7b297285653",
    path: "/product/shveps",
    meta: {"ID":"d920af15-b5d0-4587-a63b-a7b297285653","Title":"Tyo (Ростов-на-Дону) — Доставка Швепс на дом","GroupID":"45a5056a-d651-4fe2-9bcb-1c707ba7681d","SubgroupID":null,"GroupTitle":"Напитки","GroupLink":"/menu/napitki","MetaTags":[{"Content":"Швепс %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Швепс %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|db449fa1-5c4e-4fe7-b420-a02dcedbabd6",
    path: "/product/db449fa1-5c4e-4fe7-b420-a02dcedbabd6",
    meta: {"ID":"db449fa1-5c4e-4fe7-b420-a02dcedbabd6","Title":"Tyo (Ростов-на-Дону) — Доставка Панг-Тао на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Панг-Тао %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Панг-Тао %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|dcb925d8-d003-4267-bd81-52bc947c8d3c",
    path: "/product/pad-tay-s-kuritsey",
    meta: {"ID":"dcb925d8-d003-4267-bd81-52bc947c8d3c","Title":"Tyo (Ростов-на-Дону) — Доставка Пад-тай с курицей на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Пад-тай с курицей %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пад-тай с курицей %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|dceb49b3-3851-4501-b827-663d0909f9e2",
    path: "/product/kitayskaya-lapsha-vegan",
    meta: {"ID":"dceb49b3-3851-4501-b827-663d0909f9e2","Title":"Tyo (Ростов-на-Дону) — Доставка Китайская лапша веган на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Китайская лапша веган %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Китайская лапша веган %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|1c759bdb-1d66-4558-9529-b0c000c712f6|dd204703-e55a-4d0e-a580-4a3967c33228",
    path: "/product/nasan-tempura",
    meta: {"ID":"dd204703-e55a-4d0e-a580-4a3967c33228","Title":"Tyo (Ростов-на-Дону) — Доставка Насан темпура на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"1c759bdb-1d66-4558-9529-b0c000c712f6","GroupTitle":"TEMPURA ROLLS","GroupLink":"/menu/tempura-rolls","MetaTags":[{"Content":"Насан темпура %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Насан темпура %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|dd2a6342-9e82-4b7a-9129-361059290cac",
    path: "/product/rebra-barbekyu-zhareniy-zhasmin-i-kimchi",
    meta: {"ID":"dd2a6342-9e82-4b7a-9129-361059290cac","Title":"Tyo (Ростов-на-Дону) — Доставка Ребра барбекю, жареный жасмин и кимчи на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Ребра барбекю, жареный жасмин и кимчи %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ребра барбекю, жареный жасмин и кимчи %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|dd429eb1-9616-4faa-bf3f-2f386e70f596",
    path: "/product/tayskiy-vok-v-kislo-sladkom-souse-s-kuritsey",
    meta: {"ID":"dd429eb1-9616-4faa-bf3f-2f386e70f596","Title":"Tyo (Ростов-на-Дону) — Доставка Тайский вок в кисло-сладком соусе с курицей на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Тайский вок в кисло-сладком соусе с курицей %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тайский вок в кисло-сладком соусе с курицей %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|df114ddc-3647-482c-9ae8-4fbc6250b9d1",
    path: "/product/zheltoe-karri-s-tsyplenkom",
    meta: {"ID":"df114ddc-3647-482c-9ae8-4fbc6250b9d1","Title":"Tyo (Ростов-на-Дону) — Доставка Желтое карри с цыпленком на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Желтое карри с цыпленком %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Желтое карри с цыпленком %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|e19ef88e-e110-4c2c-9006-38277821cc8d",
    path: "/product/tayskiy-vok-v-kislo-sladkom-souse-s-krevetkoy",
    meta: {"ID":"e19ef88e-e110-4c2c-9006-38277821cc8d","Title":"Tyo (Ростов-на-Дону) — Доставка Тайский вок в кисло-сладком соусе с креветкой на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Тайский вок в кисло-сладком соусе с креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тайский вок в кисло-сладком соусе с креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|e837c0ec-0384-4a0c-86ea-d62bd9dd7044",
    path: "/product/zheltoe-karri-s-krevetkami",
    meta: {"ID":"e837c0ec-0384-4a0c-86ea-d62bd9dd7044","Title":"Tyo (Ростов-на-Дону) — Доставка Желтое карри с креветками на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Желтое карри с креветками %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Желтое карри с креветками %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|82c5154c-7170-47ac-a475-b0c000dc8f7f|ea570e06-1acb-482c-b5ba-57584cddb723",
    path: "/product/krasnoe-karri-so-svininoy-tya-syu",
    meta: {"ID":"ea570e06-1acb-482c-b5ba-57584cddb723","Title":"Tyo (Ростов-на-Дону) — Доставка Красное карри со свининой тя-сю на дом","GroupID":"82c5154c-7170-47ac-a475-b0c000dc8f7f","SubgroupID":null,"GroupTitle":"MAIN COURSE","GroupLink":"/menu/main-course","MetaTags":[{"Content":"Красное карри со свининой тя-сю %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Красное карри со свининой тя-сю %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|788ab5d8-d06f-44e4-88c6-b0c000c6f623|ed39bd9e-4723-4c6b-89ef-4c46174b4cb6",
    path: "/product/tori-unagi",
    meta: {"ID":"ed39bd9e-4723-4c6b-89ef-4c46174b4cb6","Title":"Tyo (Ростов-на-Дону) — Доставка Тори унаги на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"788ab5d8-d06f-44e4-88c6-b0c000c6f623","GroupTitle":"BAKED ROLLS","GroupLink":"/menu/baked-rolls","MetaTags":[{"Content":"Тори унаги %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тори унаги %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4993b01-9066-4989-a427-b0c000c6d023|f0aa7933-2bf9-4045-8d4b-6c5c2ddc7e69",
    path: "/product/f0aa7933-2bf9-4045-8d4b-6c5c2ddc7e69",
    meta: {"ID":"f0aa7933-2bf9-4045-8d4b-6c5c2ddc7e69","Title":"Tyo (Ростов-на-Дону) — Доставка Чайсай на дом","GroupID":"f2fe06d3-5494-4cb4-992a-b0bd00e8979c","SubgroupID":"e4993b01-9066-4989-a427-b0c000c6d023","GroupTitle":"CALIFORNIA ROLLS","GroupLink":"/menu/rolls","MetaTags":[{"Content":"Чайсай %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Чайсай %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|d9e3a4a1-d680-43e9-a381-b0ff008b835c|f1220b72-78be-4ecb-ad7c-66cd1919c2f8",
    path: "/product/kapusta-kimchi",
    meta: {"ID":"f1220b72-78be-4ecb-ad7c-66cd1919c2f8","Title":"Tyo (Ростов-на-Дону) — Доставка Капуста кимчи на дом","GroupID":"d9e3a4a1-d680-43e9-a381-b0ff008b835c","SubgroupID":null,"GroupTitle":"STARTERS & SALADS","GroupLink":"/menu/starters-salads","MetaTags":[{"Content":"Капуста кимчи %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Капуста кимчи %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|f91dd3bb-3468-46d9-87f3-b0bd00e87837|f3dac996-39e8-410f-8a7e-2bbe327afda2",
    path: "/product/pad-tay-s-krevetkoy",
    meta: {"ID":"f3dac996-39e8-410f-8a7e-2bbe327afda2","Title":"Tyo (Ростов-на-Дону) — Доставка Пад-тай с креветкой на дом","GroupID":"f91dd3bb-3468-46d9-87f3-b0bd00e87837","SubgroupID":null,"GroupTitle":"WOK","GroupLink":"/menu/wok","MetaTags":[{"Content":"Пад-тай с креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пад-тай с креветкой %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|755e4926-146b-469e-8146-b0c000e0985e|fc81a2fe-dabe-4613-a403-c1a59161033a",
    path: "/product/lepeshka-roti-s-tsyplenok-ogurtsami-i-zelenym-mango",
    meta: {"ID":"fc81a2fe-dabe-4613-a403-c1a59161033a","Title":"Tyo (Ростов-на-Дону) — Доставка Лепешка роти с цыпленком, огурцами и зеленым манго на дом","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"GroupTitle":"OUR SPECIALITIES","GroupLink":"/menu/our-specialities","MetaTags":[{"Content":"Лепешка роти с цыпленком, огурцами и зеленым манго %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лепешка роти с цыпленком, огурцами и зеленым манго %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "product|755e4926-146b-469e-8146-b0c000e0985e|ff89cee4-144d-41fe-95dc-181d8b11f2ad",
    path: "/product/gedza-utka",
    meta: {"ID":"ff89cee4-144d-41fe-95dc-181d8b11f2ad","Title":"Tyo (Ростов-на-Дону) — Доставка Гедза утка на дом","GroupID":"755e4926-146b-469e-8146-b0c000e0985e","SubgroupID":null,"GroupTitle":"OUR SPECIALITIES","GroupLink":"/menu/our-specialities","MetaTags":[{"Content":"Гедза утка %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Гедза утка %product.price% рублей – в меню на сайте «Tyo». Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}]},
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/menu/product/[id].vue")
  },
  {
    name: "legal-pay-rules",
    path: "/rules",
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/legal.vue")
  },
  {
    name: "legal-personal-data",
    path: "/personaldata",
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/legal.vue")
  },
  {
    name: "legal-agreement",
    path: "/privacy",
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/legal.vue")
  },
  {
    name: "legal-points",
    path: "/points",
    component: () => import("D:/deploy/UploadHidden/20241212-T903.514/arora-front/satellite/build-rest-1be7e288-8255-4d77-9ddd-6a2d48028566/pages/legal.vue")
  }
]